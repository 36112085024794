import moment from "moment";
import {
  ACCOUNT_SETTINGS_KEY,
  ADMIN_SETTINGS_KEY,
  AUTH_DEFAULT_APP,
  AUTH_HEAD_OFFICE_ID_KEY,
  AUTH_ROLE_KEY,
  AUTH_SWITCHED_APP,
  AUTH_SWITCHED_COMPANY_EMAIL_KEY,
  AUTH_SWITCHED_COMPANY_ID_KEY,
  AUTH_USER_KEY,
  BANKS_KEY,
  CACHE_STAMP,
  COUNTRIES_KEY,
  CRYPTO_KEY,
  CURRENCIES_KEY,
  NHIF_RATES_KEY,
  NOTIFICATION_STORE,
  NSSF_RATES_KEY,
  PAYE_RATES_KEY,
  SECTORS_KEY,
  SELECTED_COMPANY_KEY,
  SELECTED_EMPLOYEE_ID_FOR_PORTAL,
  SELECTED_EXPENSE_CATEGORY_KEY,
  SELECTED_MUSTER_ROLL,
  SELECTED_PROJECT_KEY,
  SELECTED_TRANSACTION,
  SUBSCRIPTION_KEY,
  SWITCHED_COUNTRY_KEY,
  SYSTEM_ADMIN
} from "../constants/SessionKeys";
import SimpleCrypto from "simple-crypto-js";
import { SWITCH_COMPANY } from "../appRedux/actions/common/CompanyActions";
import { getGlobalVariable, hasGlobalVariable, setGlobalVariable } from "./GlobalVariable";
import { API_ENDPOINT, REACT_APP_ON_BOARDING_API_ADDRESS, SIGNIN_USER } from "../constants/ActionTypes";
import React from "react";
import axios from 'axios';

const dateFormat = "YYYY-MM-DD HH:mm:ss";

export class Helpers {
  static getSession() {
    let authUserStr = this.getItem(AUTH_USER_KEY);
    let authUser =null;
    try {
      authUser = JSON.parse(authUserStr);
    } catch (e) {
      authUser = authUserStr;
    }
    if (authUser && authUser.hasOwnProperty('user') && authUser.user.person) {
      return authUser;
    }
    return null;
  }

  static isLoggedIn() {
    return this.getSession() !== null;
  }

  static token() {
    return this.isLoggedIn() ? this.getSession().user.auth_token : null;
  }

  static authUser() {
    return this.isLoggedIn() ? this.getSession().user : null;
  }

  static authUserName() {
    const authUser = this.authUser();
    if (authUser == null || authUser.person == null) {
      return "Your Name";
    }
    return this.fullName(authUser.person);
  }

  static authUserAddress() {
    const authUser = this.authUser();
    if (authUser == null || authUser.address == null) {
      return {};
    }
    return authUser.address;
  }

  static authUserID() {
    const authUser = this.authUser();
    if (this.isNull(authUser)) {
      return -1;
    }
    return authUser.id;
  }

  static authUserEmail() {
    const authUser = this.authUser();
    if (this.isNull(authUser)) {
      return '';
    }
    return authUser.email;
  }

  static authAvatarSrc() {
    const authUser = this.authUser();
    if (authUser == null || authUser.person == null) {
      return "";
    }
    return authUser.avatar;
  }

  static updateAuthUser(key, value){
    const session = this.getSession();
    if(session && session.hasOwnProperty('user')){
      const authUser = {...session.user};
      if (key && authUser && authUser.hasOwnProperty('id') ) {
        authUser[key] = value;
        session.user = authUser;
        this.saveItem(AUTH_USER_KEY, session);
      }
    }
  }

  static getMonthName(month_no) {
    month_no = month_no && !isNaN(month_no)?Number(month_no):1;
    let month = [];
    month[1] = "JANUARY";
    month[2] = "FEBRUARY";
    month[3] = "MARCH";
    month[4] = "APRIL";
    month[5] = "MAY";
    month[6] = "JUNE";
    month[7] = "JULY";
    month[8] = "AUGUST";
    month[9] = "SEPTEMBER";
    month[10] = "OCTOBER";
    month[11] = "NOVEMBER";
    month[12] = "DECEMBER";

    return month[month_no] || "JANUARY";
  }

  static imageExist(url) {
    let img = new Image();
    img.src = url;
    return img.height != 0;
  }

  static openInNewTab(url, params=null) {
    if(params){
      url+='?';
      Object.keys(params).forEach(paramKey=>{
         url+=paramKey+'='+params[paramKey]+'&';
      });
    }
    let win = window.open(url, "_blank");
    if (win && win.hasOwnProperty("focus") && typeof win.focus == "function") {
      win.focus();
    }
  }

  static updateAuthAvatarSrc(profile_avatar) {
    let session = this.getSession();
    if (session) {
      session.user.avatar = profile_avatar;
      this.saveItem(AUTH_USER_KEY, JSON.stringify(session));
    }
  }

  static authRole() {
    let role = this.getItem(AUTH_ROLE_KEY);
    try {
      role = JSON.parse(role);
    } catch (e) {}
    if (this.isNull(role)) {
      return { name: "", permissionMap: {} };
    }
    if (!role.hasOwnProperty("permissionMap")) {
      try {
        role.permissionMap = {};
      } catch (e) {
        role = { name: "", permissionMap: {} };
      }
    }
    return role;
  }

  static authRoleName() {
    return this.authRole()["name"];
  }

  static authDefaultApp() {
    let defaultApp = { app_id: 0 };
    try {
      defaultApp = JSON.parse(
          this.getItem(AUTH_DEFAULT_APP, JSON.stringify(defaultApp))
      );
    } catch (e) {}
    return defaultApp;
  }

  static app(app_id) {
    try {
      app_id = Number(app_id);
    } catch (e) {}
    let app = "home";
    switch (app_id) {
      case 1:
        app = "attendance";
        break;
      case 2:
        app = "hrm";
        break;
      case 3:
        app = "payouts";
        break;
      default:
        app = "home";
    }
    return app;
  }
  static months() {
    return [
      {
        id: "JANUARY",
        name: "January"
      },
      {
        id: "FEBRUARY",
        name: "February"
      },
      {
        id: "MARCH",
        name: "March"
      },
      {
        id: "APRIL",
        name: "April"
      },
      {
        id: "MAY",
        name: "May"
      },
      {
        id: "JUNE",
        name: "June"
      },
      {
        id: "JULY",
        name: "July"
      },
      {
        id: "AUGUST",
        name: "August"
      },
      {
        id: "SEPTEMBER",
        name: "September"
      },
      {
        id: "OCTOBER",
        name: "October"
      },
      {
        id: "NOVEMBER",
        name: "November"
      },
      {
        id: "DECEMBER",
        name: "December"
      }
    ];
  }

  static planCan(action = ""){
    let subscription_str = this.getItem(SUBSCRIPTION_KEY);
    let subscription;
    try {
      subscription = JSON.parse(subscription_str);
    } catch (e) {
      try {
        subscription = JSON.parse(JSON.stringify(subscription_str));
      }catch (e_){
        subscription = this.getItem(SUBSCRIPTION_KEY);
      }
    }
    if(!subscription || typeof subscription != "object" ){
      return false;
    }
    if ( subscription.hasOwnProperty("plan") ){
      let plan = subscription.plan;
      if ( !plan.hasOwnProperty("permissionMap") ){
        return false;
      }

      let actions;
      if(Array.isArray(action)){
        actions = action;
      }else{
        actions = [action];
      }

      let permission_granted = false;
      actions.forEach(action_=>{
        permission_granted= (plan.permissionMap.hasOwnProperty(action_) || permission_granted);
      });

      return permission_granted;
    }

    return false;
  }

  static authUserCan(action = "") {
    let actions;
    let role = Helpers.authRole();
    if(Array.isArray(action)){
      actions = action;
    }else{
      actions = [action];
    }
    if(location.pathname.startsWith("/admin")){
      //CHECK FROM ADMIN PERMISSIONS
      if(!role.hasOwnProperty('adminPermissionMap')){
        return false;
      }
      let permission_granted = false;
      actions.forEach(action_=>{
        permission_granted= (role.adminPermissionMap.hasOwnProperty(action_) || permission_granted);
      });
      return permission_granted;
    }else{
      const conditions = ['tozza','afrisoft','workpay'];
      const email = this.authSwitchedCompanyEmail() || 'workpay';
      const canSwitchToCompany = !conditions.some(em=>email.includes(em));
      const canSwitchForEWADisbursement = role.adminPermissionMap && Object.keys(role.adminPermissionMap).some(p => p =='payouts.single_payout.run') && this.getItem(AUTH_SWITCHED_COMPANY_ID_KEY) == 425;
      if((this.isSystemAdmin() && canSwitchToCompany) || canSwitchForEWADisbursement){
        //CHECK FROM ADMIN PERMISSIONS
        if(!role.hasOwnProperty('adminPermissionMap')){
          return false;
        }
        let permission_granted = false;
        actions.forEach(action_=>{
          permission_granted= (role.adminPermissionMap.hasOwnProperty(action_) || permission_granted);
        });
        return permission_granted;
      }else{
        //CHECK FROM CLIENT PERMISSIONS
        if(!role.hasOwnProperty('permissionMap')){
          return false;
        }
        let permission_granted = false;
        actions.forEach(action_=>{
          permission_granted= (role.permissionMap.hasOwnProperty(action_) || permission_granted);
        });
        return permission_granted;
      }
    }
  }

  static authUserPermissionStage(action = ""){
    if(typeof action != 'string'){
      return 0;
    }
    let role = Helpers.authRole();
    if(location.pathname.startsWith("/admin")){
      //CHECK FROM ADMIN PERMISSIONS
      if(!role.hasOwnProperty('adminPermissionMap')){
        return false;
      }
      let permission_granted = role.adminPermissionMap.hasOwnProperty(action);
      if(!permission_granted){
        return 0;
      }
      let permissionID = role.adminPermissionMap[action];
      if(role.hasOwnProperty('permissionIDs') && role.permissionIDs.hasOwnProperty(permissionID)){
        return role.permissionIDs[permissionID];
      }
    }else{
      const conditions = ['tozza','afrisoft','workpay'];
      const email = this.authSwitchedCompanyEmail() || 'workpay';
      const canSwitchToCompany = !conditions.some(em=>email.includes(em));
      if(this.isSystemAdmin() && canSwitchToCompany){
        //CHECK FROM ADMIN PERMISSIONS
        if(!role.hasOwnProperty('adminPermissionMap')){
          return false;
        }
        let permission_granted = role.adminPermissionMap.hasOwnProperty(action);
        if(!permission_granted){
          return 0;
        }
        let permissionID = role.adminPermissionMap[action];
        if(role.hasOwnProperty('permissionIDs') && role.permissionIDs.hasOwnProperty(permissionID)){
          return role.permissionIDs[permissionID];
        }
      }else{
        //CHECK FROM CLIENT PERMISSIONS
        if(!role.hasOwnProperty('permissionMap')){
          return false;
        }
        let permission_granted = role.permissionMap.hasOwnProperty(action);
        if(!permission_granted){
          return 0;
        }
        let permissionID = role.permissionMap[action];
        if(role.hasOwnProperty('permissionIDs') && role.permissionIDs.hasOwnProperty(permissionID)){
          return role.permissionIDs[permissionID];
        }
      }
    }
    return 0;
  }

  static authUserHasPermissions() {
    let role = Helpers.authRole();
    if (this.isSystemAdmin()) {
      if (!role.hasOwnProperty("adminPermissionMap")) {
        return false;
      }
      return Object.keys(role.adminPermissionMap).count() > 0;
    }
    if (!role.hasOwnProperty("permissionMap")) {
      return false;
    }
    return Object.keys(role.permissionMap).count() > 0;
  }

  static authUserCanAccessPortal() {
    let role = Helpers.authRole();
    return role.has_portal_access;
  }

  static authUserEmployeeID() {
    let role = Helpers.authRole();
    try {
      if (isNaN(role.employee_id)) {
        return null;
      }
      return Number(role.employee_id);
    } catch (e) {}
    return null; //very important
  }

  static selectedPortalEmployee() {
    let employee_str = Helpers.getItem(SELECTED_EMPLOYEE_ID_FOR_PORTAL);
    if(employee_str && employee_str.hasOwnProperty("id")){
      return employee_str;
    }
    try {
      let employee = JSON.parse(employee_str);
      if (employee && employee.hasOwnProperty("id")) {
        return employee;
      } else {
        return {id:this.authUserEmployeeID(), for:'auth_employee'};
      }
    } catch (e) {}
    return {id:this.authUserEmployeeID(), for:'auth_employee'};
  }

  static selectedPortalEmployeeID() {
    let employee = this.selectedPortalEmployee();
    if(employee){
      return employee.id
    }
    return null;
  }

  static authUserAtOwnPortal(){
     let authUserEmployeeID = this.authUserEmployeeID();
     if(!authUserEmployeeID || isNaN(authUserEmployeeID)){
       return false;
     }
     let selectedPortalEmployeeID = this.selectedPortalEmployeeID();
     if(!selectedPortalEmployeeID || isNaN(selectedPortalEmployeeID)){
       return false;
     }
     return Number(authUserEmployeeID) ===  Number(selectedPortalEmployeeID);
  }

  static authorizeFor() {
    let isAtPortal = window.location.pathname.startsWith("/portal");
    if(isAtPortal){
      let selectedEmployeeId = Helpers.selectedPortalEmployeeID();
      let authUserEmployeeId = Helpers.authUserEmployeeID();
      let authorizeFor =  (Number(selectedEmployeeId) === Number(authUserEmployeeId))?'employee':'admin_at_portal';
      return {authorizeFor:authorizeFor, employeeID:selectedEmployeeId};
    }
    let isAtProductAdmin = window.location.pathname.startsWith("/admin");
    if(isAtProductAdmin){
      return {authorizeFor:'product_admin', employeeID:null};
    }
    return {authorizeFor:'admin', employeeID:null};
  }

  static isAtPortal(){
    return  window.location.pathname.startsWith("/portal");
  }

  static isAtAttendance(){
    return  window.location.pathname.startsWith("/attendance");
  }

  static isAtHRM(){
    return  window.location.pathname.startsWith("/hrm");
  }

  static authSwitchedDepartment(){
    let role = this.authRole();
    if(!role){
      return null;
    }
    if(role.pivot && role.pivot.department){
      return role.pivot.department;
    }
    return null;
  }

  static authSwitchedDepartmentID(){
    let role = this.authRole();
    if(!role){
      return null;
    }
    if(role.pivot && role.pivot.department_id){
      return role.pivot.department_id;
    }
    return null;
  }

  static testSimpleCrypto() {
    let simpleCrypto = new SimpleCrypto(CRYPTO_KEY);
    let plainText = "Hello World!";
    let cipherText = simpleCrypto.encrypt(plainText);
    let decipherText = simpleCrypto.decrypt(cipherText);
  }

  static encrypt(plainText) {
    if (this.isNull(plainText)) {
      return plainText;
    }
    let simpleCrypto = new SimpleCrypto(CRYPTO_KEY);
    try {
      return simpleCrypto.encrypt(plainText);
    } catch (e) {
      return plainText;
    }
  }

  static decrypt(encryptedText) {
    if (this.isNull(encryptedText)) {
      return encryptedText;
    }
    let simpleCrypto = new SimpleCrypto(CRYPTO_KEY);
    try {
      return simpleCrypto.decrypt(encryptedText);
    } catch (e) {
      return encryptedText;
    }
  }

  static solveStoragePermissions(){

  }

  static saveItem(key, value) {
    let encrypt = false;
    if (!this.isNull(value)) {
      if (typeof value !== "string") {
        value = JSON.stringify(value);
      }
      encrypt = true;
    }
    if(hasGlobalVariable(key) && getGlobalVariable(key) === value){//avoid saving same data to local storage
      return true;
    }

    setGlobalVariable(key, value);
    if(encrypt){
      value = this.encrypt(value);
    }
    if(document.hasOwnProperty('hasStorageAccess') && typeof document.hasStorageAccess == 'function'){
      document.hasStorageAccess().then(hasAccess => {
        if (!hasAccess) {
          return document.requestStorageAccess();
        }
      }).then(_ => {
        document.hasStorageAccess().then(hasAccess => {
          if(hasAccess){
            localStorage[key] = value;//save to local storage
          }
        })
      }).catch((e) => {
        //console.log("hasStorageAccess() failed", e);
      });
    }else{
      localStorage[key] = value;//save to local storage
    }

    return true;
  }

  static removeItem(key) {
    localStorage.removeItem(key);
  }

  static getItem(key, default_value) {
    if(hasGlobalVariable(key)){//avoid reading local storage if value is in the global cache
      let value = getGlobalVariable(key);
      if (this.isNull(value) && default_value) {
        return default_value;
      }
      return value;
    }
    let value = localStorage.getItem(key);
    if (this.isNull(value) && default_value) {
      return default_value;
    }else{
      try{
        value = this.decrypt(value);
      }catch (e) {}
    }
    setGlobalVariable(key, value);
    return value;
  }

  static authSwitchedCompanyID() {
    if (this.isNull(this.authCurrentHeadOfficeID())) {
      let user = this.authUser();
      if(user && !this.isNull(user.company_id) && user.company_id !== "" && user.company_id !== "null"){
        return Number(user.company_id);
      }
      return null;
    }
    const id = this.getItem(AUTH_SWITCHED_COMPANY_ID_KEY);
    if (this.isNull(id) || id === "" || id === "null") {
      return null;
    }
    return id?Number(id):null;
  }

  static authSwitchedCompanyEmail() {
    if (this.isNull(this.authCurrentHeadOfficeID())) {
      return null;
    }
    const email = this.getItem(AUTH_SWITCHED_COMPANY_EMAIL_KEY);
    if (this.isNull(email) || email === "" || email === "null") {
      return null;
    }
    return email;
  }

  static authCurrentHeadOfficeID() {
    const id = this.getItem(AUTH_HEAD_OFFICE_ID_KEY);
    if (this.isNull(id) || id === "" || id === "null") {
      return null;
    }
    return id?Number(id):id;
  }

  static authSwitchedCountry(){
    const country_str = this.getItem(SWITCHED_COUNTRY_KEY);
    try{
      let country = JSON.parse(country_str);
      if(country && country.hasOwnProperty('id')){
        return country;
      }
    }catch (e) {
      if(country_str && typeof country_str !== "string" && country_str.hasOwnProperty("id")){
        return country_str
      }
    }
    let countries = this.countries();
    if(countries){
      let country = Object.values(countries).whereFirst(record=>record.alpha_two_code === 'KE');
      if(country){
        Helpers.saveItem(SWITCHED_COUNTRY_KEY, country);
        return country;
      }
    }
    return null;
  }

  static clearAuthData() {
    this.removeItem(AUTH_USER_KEY);
    this.removeItem(AUTH_SWITCHED_COMPANY_ID_KEY);
    this.removeItem(AUTH_HEAD_OFFICE_ID_KEY);
    this.removeItem(AUTH_DEFAULT_APP);
    this.removeItem(AUTH_ROLE_KEY);
    this.removeItem(SUBSCRIPTION_KEY);
    this.removeItem(SELECTED_MUSTER_ROLL);
    this.removeItem(SELECTED_EMPLOYEE_ID_FOR_PORTAL);
    this.removeItem(SELECTED_COMPANY_KEY);
    this.removeItem(SELECTED_EXPENSE_CATEGORY_KEY);
    this.removeItem(SELECTED_PROJECT_KEY);
    this.removeItem(SELECTED_TRANSACTION);
    this.removeItem(AUTH_SWITCHED_APP);
    this.removeItem(SYSTEM_ADMIN);
    this.removeItem(ACCOUNT_SETTINGS_KEY);
    this.removeItem(ADMIN_SETTINGS_KEY);
  }

  static isNull(variable) {
    return variable === undefined || variable === null || variable ==="";
  }
  static recordFromNonIndexedDataWithDefault(Data, id, defaultData) {
    if (this.isNull(Data) || this.isNull(id)) {
      return [];
    }
    let records = Data.filter(item => {
      return item.id === id;
    });
    if (this.isNull(records) || records.length === 0) {
      return defaultData;
    }
    let data = Object.assign([], records);
    return data[0];
  }
  static recordFromIndexedData(indexedData, key) {
    if (this.isNull(indexedData) || this.isNull(key)) {
      return [];
    }
    let record = null;
    if (indexedData.hasOwnProperty(key)) {
      record = indexedData[key];
    }
    if (this.isNull(record)) {
      return [];
    }
    return record;
  }

  static recordFromIndexedDataWithDefault(indexedData, key, default_record) {
    if (this.isNull(indexedData) || this.isNull(key)) {
      return default_record;
    }
    let record = null;
    if (indexedData.hasOwnProperty(key)) {
      record = indexedData[key];
    }
    if (this.isNull(record)) {
      return default_record;
    }
    return record;
  }

  static headOffice(indexedData) {
    const currentHeadOfficeID = this.authCurrentHeadOfficeID();
    if (!currentHeadOfficeID) {
      return {};
    }
    const head_office = indexedData[currentHeadOfficeID];
    if (this.isNull(head_office)) {
      return {};
    }
    return head_office;
  }

  static branch(indexedData) {
    return this.currentSwitchedBranch(indexedData);
  }

  static currentSwitchedBranch(indexedData) {
    const branches = this.currentCompanyBranches(indexedData);
    const branch = this.recordFromNonIndexedDataWithDefault(branches, this.authSwitchedCompanyID(), null);
    if (this.isNull(branch)) {
      return {};
    }
    return branch;
  }

  static currentCompanyBranch(indexedData, branchID) {
    const branches = this.currentCompanyBranches(indexedData);
    const branch = this.recordFromNonIndexedDataWithDefault(branches, branchID, null);
    if (this.isNull(branch)) {
      return {};
    }
    return branch;
  }

  static checkSetting(setting){
    const settings = this.accountSettings();
    if(this.isNull(settings)){
      return false;
    }
    return settings[setting] === 1;
  }
  static settingValue(index){
    let settings = this.accountSettings();
    if(typeof  settings === 'undefined' || !settings){
      settings =[];
      return '';
    }
    if(settings.hasOwnProperty(index)){
      return settings[index];
    }
  }
  static companyBranches(indexedData, headOfficeID) {
    if (this.isNull(headOfficeID) || this.isNull(indexedData)) {
      return [];
    }
    const company = indexedData[headOfficeID];
    if (this.isNull(company)) {
      return [];
    }
    const companyCopy = { ...company, branches: [ ...company.branches ] }; // as much as the properties of object company are copied, the inner nested branch objects needs copying as well to avoid pointing at same objects.
    const companyBranches = companyCopy.branches;
    let branches = [];
    if (!this.isNull(companyBranches)) {
      branches = companyBranches;
      companyCopy.branches = []; // about to add a head-office as a branch thus empty its branches which have already be collected for results
      branches.unshift(companyCopy);
    }
    return branches;
  }
  static currentCompanyBranches(indexedData,head_office_id = null) {

    let switchedCompanyID = this.authSwitchedCompanyID();
    let currentHeadOfficeID = this.authCurrentHeadOfficeID();
    if (!this.isNull(head_office_id)){
      currentHeadOfficeID = head_office_id;
    }
    if (
        this.isNull(switchedCompanyID) ||
        this.isNull(currentHeadOfficeID) ||
        this.isNull(indexedData)
    ) {
      return [];
    }
    const company = indexedData[currentHeadOfficeID];
    if (this.isNull(company)) {
      return [];
    }
    const companyCopy = { ...company, branches: [ ...company.branches ] }; // as much as the properties of object company are copied, the inner nested branch objects needs copying as well to avoid pointing at same objects.
    const companyBranches = companyCopy.branches;
    let branches = [];
    if (!this.isNull(companyBranches)) {
      if (switchedCompanyID === currentHeadOfficeID || !this.isNull(head_office_id)) {
        branches = companyBranches;
        companyCopy.branches = []; // about to add a head-office as a branch thus empty its branches which have already be collected for results
        branches.push(companyCopy);
      } else {
        const branch =  this.recordFromNonIndexedDataWithDefault(companyBranches, switchedCompanyID, null);
        if (!this.isNull(branch)) {
          branches = [branch];
        }
      }
    }
    return branches;
  }

  static payoutPaymentMethods(user_type) {
    let methods = [
      { name: "Mpesa", id: "MPESA" },
      { name: "Bank", id: "BANK" },
      { name: "Paybill", id: "PAYBILL" },
      { name: "Buy Goods & Services", id: "TILL" },
      { name: 'MTN', id: 'MTN'},
      { name: 'Airtel', id: 'AIRTEL'},
      { name: 'Tigo', id: 'TIGO'},
      { name: 'Telcom', id: 'TELCOM'},
    ];
    if(user_type === "EMPLOYEE"){
      methods = [];//empty the methods = for employee use the below methods only
      let wallet_enabled = Helpers.checkSetting('use_mywallet');
      if(wallet_enabled){
        methods.push({ name: "MyWallet", id: "MyWallet" });
      }
      methods.unshift({ name: "Employee Default Payment Method", id: "OTHER" });
    }
    return methods;
  }

  static expensePaymentMethods(expense_type){
    let methods =[
      { name: "Mpesa", id: "MPESA" },
      { name: "Bank", id: "BANK" },
      { name: "Paybill", id: "PAYBILL" },
      { name: "Buy Goods & Services", id: "TILL" },
      { name: "Cash", id: "CASH" }
    ];
    if(expense_type === 'EMPLOYEE'){
      let wallet_enabled = Helpers.checkSetting('use_mywallet');
      if(wallet_enabled){
        methods.push({ name: "MyWallet", id: "MyWallet" });
      }
      methods.push({ name: "Employee Default Payment Method", id: "OTHER" });
    }
    return methods;
  }

  static renderFavouriteMenuItems(data){
    if (data.length > 0 ){
      let dt=[];
      data.forEach((s) => {
        if(!this.isNull(s.name) && !this.isNull(s.id)) {
          let method =s.payment_method ;
          switch (s.payment_method) {
            case "PAYBILL":
              method += " - "+s.paybill_no+" - "+ s.acc_no;
              break;
            case "BANK":

              method += " - "+ s.acc_no;
              break;
            case "TILL":
              method += " - "+s.till_no+" - "+ s.acc_no;
              break;
            default:
              method += " - "+  s.mobile;
              break;

          }
          let label =  s.name ? s.name : s;
          label = label.toProperCase();
          label += " ("+ method+" )";
          dt.push({value: s.id ? s.id : s, label: label });
        }
      });
      return dt;
    }
  }

  static recipientTypes(from='payouts') {
    let types =  [];
    if(from === 'payouts'){
      types.push({ name: "EMPLOYEE", id: "EMPLOYEE" });
    }
    types.push({ name: "FAVOURITE", id: "FAVOURITE" });
    types.push({ name: "OTHER", id: "OTHER" });
    return types;
  }
  static feeTypes() {
    return [
      {
        id: "SHARE",
        name: "Share"
      },
      {
        id: "RECEIVER",
        name: "Receiver"
      },
      {
        id: "SENDER",
        name: "Sender"
      }
    ];
  }
  static defaultPaymentMethods() {
    return [
      {
        id: "MPESA",
        name: "Mpesa"
      },
      {
        id: "EQUITEL",
        name: "Equitel"
      },
      {
        id: "CARD",
        name: "Card"
      },
      {
        id: "MyWallet",
        name: "Workpay Wallet"
      }
    ];
  }
  static nhif_rates() {
    let nhif_rates = null;
    try {
      nhif_rates = JSON.parse(this.getItem(NHIF_RATES_KEY));
      if (
          Array.isArray(nhif_rates) ||
          typeof nhif_rates !== "object" ||
          Object.keys(nhif_rates).length === 0
      ) {
        return null;
      }
    } catch (e) {}
    return nhif_rates;
  }

  static nssf_rates() {
    let nssf_rates = null;
    try {
      nssf_rates = JSON.parse(this.getItem(NSSF_RATES_KEY));
      if (
          Array.isArray(nssf_rates) ||
          typeof nssf_rates !== "object" ||
          Object.keys(nssf_rates).length === 0
      ) {
        return null;
      }
    } catch (e) {}
    return nssf_rates;
  }

  static paye_rates() {
    let paye_rates = null;
    try {
      paye_rates = JSON.parse(this.getItem(PAYE_RATES_KEY));
      if (
          Array.isArray(paye_rates) ||
          typeof paye_rates !== "object" ||
          Object.keys(paye_rates).length === 0
      ) {
        return null;
      }
    } catch (e) {}
    return paye_rates;
  }

  static sectors() {
    let sectors = null;
    try {
      sectors = JSON.parse(this.getItem(SECTORS_KEY));
      if (
          Array.isArray(sectors) ||
          typeof sectors !== "object" ||
          Object.keys(sectors).length === 0
      ) {
        return null;
      }
    } catch (e) {}
    return sectors;
  }

  static banks() {
    let banks = null;
    try {
      banks = JSON.parse(this.getItem(BANKS_KEY));
      if (
          Array.isArray(banks) ||
          typeof banks !== "object" ||
          Object.keys(banks).length === 0
      ) {
        return null;
      }
    } catch (e) {}
    return banks;
  }

  static currencies() {
    let currencies = null;
    try {
      const currencyData =this.getItem(CURRENCIES_KEY);
      currencies = typeof  currencyData === 'string'?JSON.parse(currencyData):currencyData;
      if (
          Array.isArray(currencies) ||
          typeof currencies !== "object" ||
          Object.keys(currencies).length === 0
      ) {
        return null;
      }
    } catch (e) {}
    return currencies;
  }
  static getCompanyOptions = (searchText,callback)=>{
    let filter = {searching:true,searchText,token:Helpers.token(),recordsPerPage:50};
    return  axios.get(API_ENDPOINT+'/tozza/companies',{params:filter})
      .then(response=>{
        let data =[];
        let pageInfo = response.data.data;
        if (pageInfo && pageInfo.hasOwnProperty('data')){
          data = this.renderMenuItems(pageInfo.data);
        }
        return callback(data);
      })
      .catch(error=>error.response);
  };

  static countries() {
    let countries = null;
    try {
      const countryData = this.getItem(COUNTRIES_KEY);
      countries = typeof countryData ==='string' ? JSON.parse(countryData):countryData;
      if (
          Array.isArray(countries) ||
          typeof countries !== "object" ||
          Object.keys(countries).length === 0
      ) {
        return null;
      }
    } catch (e) {}
    return countries;
  }
  static accountSettings() {
    let settings ={};
    try {
      const accSettings = this.getItem(ACCOUNT_SETTINGS_KEY);
      settings = typeof accSettings ==='string'? JSON.parse(accSettings):accSettings;
      if (
          Array.isArray(settings) /*||  Helpers.isNull(settings)*/ ||
          !settings ||settings.length === 0
      ) {
        return {};
      }
    } catch (e) { }
    settings['number_of_decimals'] = 2;
    return settings;
  }
  static adminUserSettings() {
    let settings ={};
    try {
      const accSettings = this.getItem(ADMIN_SETTINGS_KEY);
      settings = typeof accSettings ==='string'? JSON.parse(accSettings):accSettings;
      if (
          Array.isArray(settings) /*||  Helpers.isNull(settings)*/ ||
          !settings ||settings.length === 0
      ) {
        return {};
      }
    } catch (e) { }
    return settings;
  }

  static cachedMinutes() {
    let cache_stamp = this.getItem(CACHE_STAMP);
    if (this.isNull(cache_stamp)) {
      this.saveItem(CACHE_STAMP, moment(new Date()).format(dateFormat));
      cache_stamp = this.getItem(CACHE_STAMP);
    }
    const cache_time = moment(cache_stamp);
    const now = moment(new Date());
    const duration = moment.duration(now.diff(cache_time));
    return duration.asMinutes();
  }

  static updateCacheStamp() {
    this.saveItem(CACHE_STAMP, moment(new Date()).format(dateFormat));
  }

  static number_format(number,decimals = 2) {
    if (this.isNull(number) || number === "") {
      number = "0";
    }
    if (isNaN(number)) {
      number = "0";
    }

    let string_val = parseFloat(number)
      .toFixed(decimals)
      .toString();
    let number_decimals_ = string_val.split('.', 2);
    let _decimals_ = (number_decimals_.length > 1)?number_decimals_[1]:'';
    let _number = (number_decimals_.length > 0)?number_decimals_[0]:'0';
    return (_number.replace(/\B(?=(\d{3})+(?!\d))/g, "$&,"))+((_decimals_!=='')?'.'+_decimals_:'');
  }

  static ordinal_number(number) {
    if(number % 100 >= 11 && number % 100 <= 13) {
      return number + "th";
    }

    switch(number % 10) {
      case 1: return number + "st";
      case 2: return number + "nd";
      case 3: return number + "rd";
    }

    return number + "th";
  }

  static titles() {
    const titles = ["MRS", "MS", "MR", "MISS", "PROF", "DR", "OTHER"];
    return titles;
  }

  static educations() {
    const levels = [
      "CERTIFICATE",
      "CPA",
      "DIPLOMA",
      "DEGREE",
      "MASTERS",
      "DOCTOR",
      "PROFESSOR"
    ];
    return levels;
  }

  static marital_status() {
    const stati = ["SINGLE", "MARRIED", "DIVORCED", "WIDOW", "WIDOWED"];
    return stati;
  }

  static sortMonths(months){
    let allMonths = ['JANUARY','FEBRUARY','MARCH', 'APRIL','MAY','JUNE','JULY','AUGUST','SEPTEMBER','OCTOBER','NOVEMBER','DECEMBER'];
    months.sort(function(a,b){
      return allMonths.indexOf(a) - allMonths.indexOf(b);
    });

    return months;
  }

  static genders() {
    return ["MALE", "FEMALE", "UNKNOWN"];
  }
  static allGenders() {
    return [
      { id: "MALE", name: "Male" },
      { id: "FEMALE", name: "Female" },
      { id: "BOTH", name: "Both" }
    ];
  }
  static carryForwardOptions() {
    return [
      { id: "No", name: "No" },
      { id: "Yes", name: "Yes" }
    ];
  }

  static ethnicGroups() {
    return [
      {
        value: "None",
        label: "None"
      },
      {
        value: "Bajuni",
        label: "Bajuni"
      },
      {
        value: "Embu",
        label: "Embu"
      },
      {
        value: "Hindu",
        label: "Hindu"
      },
      {
        value: "Kalenjin",
        label: "Kalenjin"
      },
      {
        value: "Kamba",
        label: "Kamba"
      },
      {
        value: "Kikuyu",
        label: "Kikuyu"
      },
      {
        value: "Kisii",
        label: "Kisii"
      },
      {
        value: "Kore",
        label: "Kore"
      },
      {
        value: "Kuria",
        label: "Kuria"
      },
      {
        value: "Luhya",
        label: "Luhya"
      },
      {
        value: "Luo",
        label: "Luo"
      },
      {
        value: "Makonde",
        label: "Makonde"
      },
      {
        value: "Marama",
        label: "Marama"
      },
      {
        value: "Maasai",
        label: "Maasai"
      },
      {
        value: "Meru",
        label: "Meru"
      },
      {
        value: "Mijikenda",
        label: "Mijikenda"
      },
      {
        value: "Ogiek",
        label: "Ogiek"
      },
      {
        value: "Orma",
        label: "Orma"
      },
      {
        value: "Oromo",
        label: "Oromo"
      },
      {
        value: "Pokomo",
        label: "Pokomo"
      },
      {
        value: "Rendille",
        label: "Rendille"
      },
      {
        value: "Samburu",
        label: "Samburu"
      },
      {
        value: "Somali",
        label: "Somali"
      },
      {
        value: "Suba",
        label: "Suba"
      },
      {
        value: "Swahili",
        label: "Swahili"
      },
      {
        value: "Tachoni",
        label: "Tachoni"
      },
      {
        value: "Taita",
        label: "Taita"
      },
      {
        value: "Taveta",
        label: "Taveta"
      },
      {
        value: "Teso",
        label: "Teso"
      },
      {
        value: "Turkana",
        label: "Turkana"
      },
      {
        value: "Yaaku",
        label: "Yaaku"
      }
    ];
  }

  static employment_types() {
    return [
      "PERMANENT",
      "CONTRACT",
      "CASUAL",
      "CONSULTANT",
      "COMMISSION",
      "INTERNSHIP",
      "CONTRACTOR",
    ];
  }

  static payment_methods_select() {
    let methods = [
      {
        label: "MPESA",
        value: "MPESA"
      },
      {
        label: "CASH",
        value: "CASH"
      },
      {
        label: "BANK",
        value: "BANK"
      },
      {
        label: "CHEQUE",
        value: "CHEQUE"
      }
    ];
    let wallet_enabled = Helpers.checkSetting('use_mywallet');
    if(wallet_enabled){
      methods.push({ label: "MyWallet", value: "MyWallet" });
    }
    return methods;
  }
  static payment_methods() {
    let methods = ['MPESA', 'CASH', 'BANK', 'CHEQUE'];
    let wallet_enabled = Helpers.checkSetting('use_mywallet');
    if(wallet_enabled){
      methods.push('MyWallet');
    }
    return methods;
  }
  /*added to respect react-selects*/
  static paymentMethods() {
    let methods = [
      { id: "MPESA", name: "MPESA" },
      { id: "BANK", name: "BANK" },
      { id: "CASH", name: "CASH" },
      { id: "CHEQUE", name: "CHEQUE" }
    ];
    let wallet_enabled = Helpers.checkSetting('use_mywallet');
    if(wallet_enabled){
      methods.push({ id: "MyWallet", name: "MyWallet" });
    }
    return methods;
  }
  static employmentTypes() {
    return [
      { id: "PERMANENT", name: "PERMANENT" },
      { id: "CONTRACT", name: "CONTRACT" },
      { id: "CASUAL", name: "CASUAL" },
      { id: "CONSULTANT", name: "CONSULTANT" },
      { id: "COMMISSION", name: "COMMISSION" },
      { id: "INTERNSHIP", name: "INTERNSHIP" },
      { id: "CONTRACTOR", name: "CONTRACTOR" }
    ];
  }
  static daysOfTheWeek() {
    return [
      { id: "Sunday", name: "Sunday" },
      { id: "Monday", name: "Monday" },
      { id: "Tuesday", name: "Tuesday" },
      { id: "Wednesday", name: "Wednesday" },
      { id: "Thursday", name: "Thursday" },
      { id: "Friday", name: "Friday" },
      { id: "Saturday", name: "Saturday" }
    ];
  }
  /*End: added to respect react-selects*/

  static payment_frequencies() {
    return [
      {
        label: "MONTHLY",
        value: "MONTHLY"
      },
      {
        label: "BI-WEEKLY",
        value: "BI-WEEKLY"
      },
      {
        label: "WEEKLY",
        value: "WEEKLY"
      },
      {
        label: "DAILY",
        value: "DAILY"
      }
    ];
  }
  static employeePaymentPolicies() {
    return [
      {
        name: "EMPLOYEE BASED",
        key: "EMPLOYEE",
        id: "EMPLOYEE"
      },
      {
        name: "PROJECT BASED",
        key: "PROJECT",
        id: "PROJECT"
      },
      {
        name: "PRODUCT BASED",
        key: "PRODUCT",
        id: "PRODUCT"
      }
    ];
  }
  static getEmployeePaymentPolicyTitle(policyId) {
    if (this.isNull(policyId)) {
      return "";
    }
    let policy = this.employeePaymentPolicies().where(record=>record.id === policyId);
    if (policy.length > 0) {
      return policy[0].name.toProperCase();
    }
    return "";
  }

  static weekDays() {
    return [
      {
        value: "Sunday",
        label: "Sunday"
      },
      {
        value: "Monday",
        label: "Monday"
      },
      {
        value: "Tuesday",
        label: "Tuesday"
      },
      {
        value: "Wednesday",
        label: "Wednesday"
      },
      {
        value: "Thursday",
        label: "Thursday"
      },
      {
        value: "Friday",
        label: "Friday"
      },
      {
        value: "Saturday",
        label: "Saturday"
      }
    ];
  }

  static bank_branches(indexedBanks, bank_id) {
    if (this.isNull(indexedBanks) || this.isNull(bank_id)) {
      return [];
    }
    const bank = indexedBanks[bank_id];
    if (this.isNull(bank)) {
      return [];
    }
    const branches = bank.branches;
    if (this.isNull(branches)) {
      return [];
    }
    return Object.values(branches).sort(this.sortData("name"));
  }
  static sortData(prop) {
    return function(a, b) {
      return a[prop] - b[prop];
    };
  }
  static getIDofFirstRecord(indexedData) {
    if (this.isNull(indexedData)) {
      return null;
    }
    const keys = Object.keys(indexedData);
    if (keys.length > 0) {
      return keys[0];
    }
    return null;
  }

  static toString(value) {
    if (this.isNull(value)) {
      return null;
    }
    return value.toString();
  }

  static employeeExitStatus(record) {
    if (this.isNull(record)) {
      return "";
    }
    if (!this.isNull(record.approved_by)) {
      return "Approved";
    }
    if (!this.isNull(record.disapproved_by)) {
      return "Disapproved";
    }
    if (!this.isNull(record.certified_by)) {
      return "Certified";
    }
    if (!this.isNull(record.rejected_by)) {
      return "Rejected";
    }
    if (this.isNull(record.certified_by) && this.isNull(record.rejected_by)) {
      return "Pending Certification";
    }
    if (this.isNull(record.approved_by) && this.isNull(record.disapproved_by)) {
      return "Pending Approval";
    }
  }

  static employment_typess() {
    return [
      {
        value: "PERMANENT",
        label: "PERMANENT"
      },
      {
        value: "CONTRACT",
        label: "CONTRACT"
      },
      {
        value: "CASUAL",
        label: "CASUAL"
      },
      {
        value: "CONSULTANT",
        label: "CONSULTANT"
      },
      {
        value: "COMMISSION",
        label: "COMMISSION"
      },
      {
        value: "INTERNSHIP",
        label: "INTERNSHIP"
      },
      {
        value: "CONTRACTOR",
        label: "CONTRACTOR"
      }
    ];
  }

  static educationLevels() {
    const levels = [
      {
        value: "CERTIFICATE",
        label: "CERTIFICATE"
      },
      {
        value: "CPA",
        label: "CPA"
      },
      {
        value: "DIPLOMA",
        label: "DIPLOMA"
      },
      {
        value: "DEGREE",
        label: "DEGREE"
      },
      {
        value: "MASTERS",
        label: "MASTERS"
      },
      {
        value: "DOCTOR",
        label: "DOCTOR"
      },
      {
        value: "PROFESSOR",
        label: "PROFESSOR"
      }
    ];
    return levels;
  }
  static marital_statuses() {
    const status = [
      {
        label: "SINGLE",
        value: "SINGLE"
      },
      {
        label: "MARRIED",
        value: "MARRIED"
      },
      {
        label: "DIVORCED",
        value: "DIVORCED"
      },
      {
        label: "WIDOW",
        value: "WIDOW"
      },
      {
        label: "WIDOWED",
        value: "WIDOWED"
      }
    ];
    return status;
  }

  static attendancePolicyTitle(text) {
    if (this.isNull(text)) {
      return "";
    }
    const policies = this.attendance_policies();
    const policy = policies.where(record => {
      return record.id === text;
    });
    if (policy.length > 0) {
      return policy[0].name.toProperCase();
    }
    return "";
  }

  static salaryReferenceTitle(text) {
    if (this.isNull(text)) {
      return "";
    }
    const references = this.salary_references().where(record => {
      return record.id === text;
    });
    if (references.length > 0) {
      return references[0].name.toProperCase();
    }
    return "";
  }

  static fullName(person) {
    if (this.isNull(person) || !person.hasOwnProperty("first_name")) {
      return "";
    }
    let full =
        person.first_name + " " + person.othernames + " " + person.surname;
    return full.toProperCase();
  }
  static surnameSplit(person, name = null) {
    if (this.isNull(person)) {
      return "";
    }
    if (!this.isNull(name)) {
      name = person.first_name + " " + person.othernames;
      return name.toProperCase();
    }
    return person.surname.toProperCase();
  }
  static notification_types() {
    return [
      "System",
      "Leave",
      "Salary-Advance",
      "Petty-Cash",
      "Payroll",
      "Loan",
      "Employee-Exit",
      "Other"
    ];
  }
  static attendance_policies() {
    return [
      {
        id: "NO",
        name: "Set Daily Working Hours"
      },
      {
        id: "IN_ONLY",
        name: "Set Daily Working Hours But must Clock In"
      },
      {
        id: "YES",
        name: "Difference between First Clock-In & First Clock-Out"
      },
      {
        id: "FIRST_IN_LAST_OUT",
        name: "Difference between First Clock-In & Last Clock-Out"
      },
      {
        id: "EVERY_HOUR_WITH_IN",
        name: "Sum of Every-Hour that have a Clock-In record"
      },
      {
        id: "ADMIN_PUNCHED_DAYS",
        name: "The Admin Entered Days & Overtime"
      }
    ];
  }

  static salary_references(){
    return [
      {
        id: "BASIC",
        name: "Basic Salary"
      },
      {
        id: "GROSS",
        name: "Gross Salary"
      },
      {
        id: "NET",
        name: "Net Salary"
      }
    ];
  }

  static payment_policies_select() {
    const options = [
      {
        value: "NO",
        label: "Set Daily Working Hours"
      },
      {
        value: "IN_ONLY",
        label: "Set Daily Working Hours But must Clock In"
      },
      {
        value: "YES",
        label: "Difference between First Clock-In & First Clock-Out"
      },
      {
        value: "FIRST_IN_LAST_OUT",
        label: "Difference between First Clock-In & Last Clock-Out"
      },
      {
        value: "EVERY_HOUR_WITH_IN",
        label: "Sum of Every-Hour that have a Clock-In record"
      },
      {
        value: "ADMIN_PUNCHED_DAYS",
        label: "The Admin Entered Days & Overtime"
      }
    ];
    return options;
  }
  static calculateInterest(
      principle,
      monthly_installments,
      monthly_rate,
      months,
      type
  ) {
    var principle = parseFloat(principle);
    var monthly_installments = parseFloat(monthly_installments);
    var monthly_rate = parseFloat(monthly_rate);
    var no_of_months = parseFloat(months);
    var amount = principle + (monthly_rate * no_of_months * principle) / 100;
    var installment = amount / no_of_months;
    let interest_type = type;
    if (interest_type === "FIXED") {
      var interest = (principle * monthly_rate) / 100;
      amount = principle + interest;
      installment = amount / no_of_months;
    } else if (interest_type === "REDUCING") {
      var fixed = principle / no_of_months;
      installment = fixed + (principle * monthly_rate) / 100;
      var balance = principle;
      var total_installs = 0;
      while (balance > 0) {
        var install = parseFloat(fixed + (balance * monthly_rate) / 100);
        balance = parseFloat(balance - fixed);
        total_installs = parseFloat(total_installs + install);
      }
      amount = total_installs;
    }
    return { amount: amount, monthly_installments: installment };
  }

  static expenseCategories(expenseCategories) {
    return expenseCategories.unique("category");
  }
  static expenseSubCategories(expenseCategories, category) {
    if (this.isNull(category) || (category === "" && category === 0)) {
      return expenseCategories;
    }
    return expenseCategories.where(record => {
      return record["category"] === category;
    });
  }
  static expenseSums(expenses) {
    let sums = {
      total: 0,
      paid: 0,
      unpaid: 0,
      total_count: 0,
      paid_count: 0,
      unpaid_count: 0
    };
    if (Helpers.isNull(expenses) || expenses.length === 0) {
      return sums;
    }
    let length = expenses.length;
    for (let i = 0; i < length; i++) {
      let record = expenses[i];
      sums.total = Number(sums.total) + Number(record.amount);
      sums.total_count++;
      if (record.status === "PAID") {
        sums.paid = Number(sums.paid) + Number(record.amount);
        sums.paid_count++;
      } else {
        sums.unpaid = Number(sums.unpaid) + Number(record.amount);
        sums.unpaid_count++;
      }
    }
    return sums;
  }
  static getFormData(requestParameters = {}) {
    const formData = new FormData();
    Object.keys(requestParameters).map(key => {
      const value = requestParameters[key];
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          let item = value[i];
          formData.append(key + "[" + i + "]", item);
        }
      } else {
        formData.append(key, value);
      }
    });
    return formData;
  }
  static plural(word, count) {
    if (this.isNull(word) || this.isNull(count)) {
      return "";
    }
    if (Number(count) === 0 || Number(count) > 1) {
      return word + "s";
    }
    return word;
  }
  static activeSubscription(obj = false) {
    let subscription_str = this.getItem(SUBSCRIPTION_KEY);
    let subscription = {};
    try {
      subscription = JSON.parse(subscription_str);
    } catch (e) {
      subscription = this.getItem(SUBSCRIPTION_KEY);
    }
    if(!subscription){
      subscription = {};
    }
    if (obj){
      if (subscription.hasOwnProperty('id')){
        return  subscription;
      }
      return  subscription[Object.keys(subscription)[0]];
    }
    if (subscription.hasOwnProperty('id')){
      return  subscription.status === 'ACTIVE';
    }
    return Object.values(subscription).length > 0 && subscription[Object.keys(subscription)[0]].status ==="ACTIVE";

  }
  static unpaidSubscription(ob =false) {
    let subscription_str = this.getItem(SUBSCRIPTION_KEY);
    let subscription = {};
    try {
      subscription = JSON.parse(subscription_str);
    } catch (e) {
      subscription = this.getItem(SUBSCRIPTION_KEY);
    }
    if(!subscription){
      subscription = {};
    }
    if (ob){
      if (subscription.hasOwnProperty('id')){
        return  subscription;
      }
      return subscription[Object.keys(subscription)[0]];
    }
    if (subscription.hasOwnProperty('id')){
      return  subscription.status === 'UNPAID';
    }
    return Object.values(subscription).length > 0 && subscription[Object.keys(subscription)[0]].status ==='UNPAID';

  }
  static choosePlanRedirect() {
    return location.href = REACT_APP_ON_BOARDING_API_ADDRESS+"/choose-plan?utm_source=mainApp&utm_content="+Helpers.token();
  }
  static currentSubscription() {
    let subscription_str = this.getItem(SUBSCRIPTION_KEY);
    let subscription = {};
    try {
      subscription = JSON.parse(subscription_str);
    } catch (e) {
      subscription = this.getItem(SUBSCRIPTION_KEY);
    }
    return subscription.plan?.plan_name

  }
  static notifications() {
    let notifications = {};
    try {
      let data = JSON.parse(Helpers.getItem(NOTIFICATION_STORE));
      if (data && !Array.isArray(data) && typeof data == "object") {
        notifications = data;
      }
    } catch (e) {}
    return notifications;
  }

  static updateNotification(notification, updates) {
    let notifications = Helpers.notifications();
    if (notifications.hasOwnProperty(notification.time)) {
      notifications[notification.time] = Object.assign(
          {},
          notification,
          updates
      );
      if (updates.hasOwnProperty("displayed")) {
        if (updates.displayed) {
          delete notifications[notification.time];
        }
      }
      Helpers.saveItem(NOTIFICATION_STORE, notifications);
    }
  }
  static pushNotification(alertMessage, type) {
    let time = moment().format(dateFormat);
    let notifications = Helpers.notifications();
    if (!Helpers.isNull(alertMessage) && alertMessage !== "") {
      let message = alertMessage;
      if (typeof alertMessage === "object") {
        message = "Connection Error";
        return;//Do not show
      }
      notifications[time] = {
        time: time,
        message: message,
        displayed: false,
        type: type
      };
      Helpers.saveItem(NOTIFICATION_STORE, notifications);
    }
  }
  static loading(props) {
    if (!props.appLoading || Object.keys(props.appLoading).length === 0) {
      return false;
    }
    if (props.appLoading[SWITCH_COMPANY] || props.appLoading[SIGNIN_USER]) {
      return true;
    }
    if (
        props.hasOwnProperty("loaderTypes") &&
        Array.isArray(props.loaderTypes)
    ) {
      let loaderTypesCount = props.loaderTypes.count();
      for (let i = 0; i < loaderTypesCount; i++) {
        let loaderType = props.loaderTypes[i];
        if (props.appLoading[loaderType]) {
          return true;
        }
      }
      return false;
    }
    return true; // =>>> Object.keys(props.appLoading).length > 0
  }
  static inArray(needle, haystack) {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
      if (haystack[i] == needle) return true;
    }
    return false;
  }
  static filterPortalData = async (data, employeeID) =>
      await new Promise(resolve => {
        let portalData = [];
        if (
            Helpers.isNull(data) ||
            Helpers.isNull(employeeID) ||
            !Array.isArray(data)
        ) {
          return resolve(portalData);
        }
        let employee_id_key = null;
        let first_record_scanned = false;
        data.forEach(record => {
          let valid = false;
          if (!first_record_scanned) {
            if (record.hasOwnProperty("employee_id")) {
              employee_id_key = "employee_id";
            } else if (record.hasOwnProperty("external_id")) {
              employee_id_key = "external_id";
            } else if (record.hasOwnProperty("client_id")) {
              employee_id_key = "client_id";
            }
          }
          try {
            if (Number(record[employee_id_key]) === Number(employeeID)) {
              valid = true;
            }
          } catch (e) {}
          first_record_scanned = true;
          if (valid) {
            portalData.push(record);
          }
        });
        resolve(portalData);
      });
  static customFilterPortalData(data, employeeID) {
    if (!Array.isArray(data)) {
      return [];
    }
    return data.where(datum => {
      let key = "";
      if (datum.hasOwnProperty("employee_id")) {
        key = "employee_id";
      } else if (datum.hasOwnProperty("external_id")) {
        key = "external_id";
      } else if (datum.hasOwnProperty("client_id")) {
        key = "client_id";
      } else {
        return true;
      }
      return parseInt(datum[key]) === parseInt(employeeID);
    });
  }
  static delay = async (time_in_millis, checker) =>
      await new Promise(resolve => {
        setTimeout(() => {
          resolve(checker);
        }, time_in_millis);
      });

  static delayAsync = (time_in_millis, checker) =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve(checker);
        }, time_in_millis);
      });

  static formValidations(inputs, state) {
    let i = 0;
    const dataState = { ...state };
    Object.keys(inputs).some(item => {
      let validator = inputs[item];
      if (!validator || !validator.hasOwnProperty("type")) {
        let actualValue = dataState[item];
        if (this.isNull(actualValue) || actualValue.length === 0) {
          i++;
          return false;
        }
      } else if (validator.hasOwnProperty("type")) {
        let actualValue = dataState[item];
        let type = validator.type;
        if (
            this.isNull(actualValue) ||
            actualValue.length === 0 ||
            typeof actualValue !== type ||
            (validator.hasOwnProperty("valueLength") &&
                actualValue.length < validator.valueLength)
        ) {
          i++;
          return false;
        }
      }
    });
    return i === 0;
  }

  static renderMenuItems = data => {
    if (data) {
      let dt = [];
      data.forEach(s => {
        if (!this.isNull(s.name) && !this.isNull(s.id)) {
          dt.push({ value: !this.isNull(s.id) ? s.id : s, label: !this.isNull(s.name) ? s.name : s });
        }
      });
      return dt;
    }
  };

  static renderPayrollBatchMenuItems = data => {
    if (data) {
      let dt = [];
      data.forEach(s => {
        if (!this.isNull(s.month) && !this.isNull(s.year)) {
          dt.push({ value: s.id, label: (s.month+' '+s.year+' '+s.payment_frequency) });
        }
      });
      return dt;
    }
  };

  static renderBranchMenuItems = data => {
    if (data){
      let dt =[];
      data.forEach(s=>{
        if (!this.isNull(s.name) && !this.isNull(s.id)) {
          if (s.is_head_office === 1 || s.is_head_office === '1'){
            s.name="Head Office";
          }
          dt.push({ value: s.id ? s.id : s, label: s.name ? s.name : s });
        }
      });
      return dt;
    }
  };

  static renderEmployeesMenuItems = (data,withAll=false) => {
    if (data) {
      let dt = [];
      if (withAll){
        dt =[{value:'all',label:'All'}];
      }
      data.forEach(s => {
        if (!this.isNull(s.person) && !this.isNull(s.id)) {
          if (
              s.status === "ACTIVE" ||
              s.status === "ON_NOTICE_OF_EXIT" ||
              s.status === "ON_LEAVE"
          ) {
            dt.push({
              value: s.id,
              label:
                  s.employee_no +
                  (s.employee_no && s.employee_no !== "" ? " - " : "") +
                  this.fullName(s.person)
            });
          }
        }
      });
      return dt;
    } else {
      return [];
    }
  };

  static renderCustomMenuItems = (data, title) => {
    if (data) {
      let dt = [];
      data.forEach(s => {
        if (!this.isNull(s[title]) && !this.isNull(s.id)) {
          dt.push({ value: s.id ? s.id : s, label: s[title] ? s[title] : s });
        }
      });
      return dt;
    }
  };
  static renderGroupedMenuItems = (data, optionName = null) => {
    if (data) {
      let dt = [];
      if (Helpers.isNull(optionName)) {
        optionName = "sub_categories";
      }
      data.forEach(s => {
        if (!this.isNull(s.name) && !this.isNull(s.id)) {
          dt.push({
            options: this.renderMenuItems(Object.values(s[optionName])),
            label: s.name ? s.name : s
          });
        }
      });
      return dt;
    }
  };

  static recordFromGroupedItems = (data, optionName, id) => {
    if (data) {
      let length = data.length;
      for(let i=0; i<length; i++){
         let s = data[i];
        if (!this.isNull(s.name) && !this.isNull(s.id)) {
          let groupItems = Object.values(s[optionName]);
          if(Array.isArray(groupItems)){
            let record = groupItems.whereFirst(record=>record.id === id);
            if(record){
               return record;
            }
          }
        }
      }
    }
    return null;
  };

  static validateKraPin(pin) {
    let isValid = false;
    if (pin.length === 0) {
      return {
        isValid: isValid,
        message: ""
      };
    } else {
      let pinLength = pin.length;
      if (pinLength < 11) {
        return {
          isValid: isValid,
          message: "The KRA PIN length should not be less than 11 characters"
        };
      }
      const firstChar = pin.charAt(0);
      const lastChar = pin.charAt(pinLength - 1);
      let interval = pin.substring(1);
      interval = interval.replace(/.$/, "");
      if (
          pinLength >= 11 &&
          firstChar.match("^[a-zA-Z]+$") &&
          lastChar.match("^[a-zA-Z]+$") &&
          interval.match("^[0-9]+$")
      ) {
        return {
          isValid: true,
          message: ""
        };
      } else {
        return {
          isValid: isValid,
          message:
              "The first and the last characters of KRA PIN should be letters,in between the first and the last should be numbers and the length should be 11"
        };
      }
    }
  }

  static isSystemAdmin() {
    if (this.getItem(SYSTEM_ADMIN) === true) {
      return true;
    }
    if (this.authUser() && this.authUser().is_system_admin === true) {
      return true;
    }
    return false;
  }
  static canAccessAppraisal() {
    if (this.authUser() && this.authUser().can_access_appraisal === true) {
      return true;
    }
    return false;
  }
  static isSecurity(companies) {
    return this.isSecurityCompany(companies);
  }
  static strChunks(str, length){
    if(!str){
      return [];
    }
    if(str.length<= length){
      return [str];
    }
    return str.match(new RegExp('.{1,' + length + '}', 'g'));
  }
  static testGV(){
    this.modifyGV();
    //console the global variable here
  }
  static modifyGV(){
    setGlobalVariable('check', 'say');
  }
  static apps(){
    return [
        {
          id:0,
          name:'None'
        },{
        id:1,
        name:'Time & Attendance'
      } ,
      {
        id:2,
        name:"HRM & Payroll",
      },{
        id:3,
        name:"Payouts",
      }
      ] ;
  }
  static isSecurityCompany(companies){
      const company = this.recordFromIndexedDataWithDefault(companies,this.authSwitchedCompanyID(),{sector:''});
      if(this.isNull(company)){
        return false;
      }
      return company.sector.toLowerCase().includes('security');
  }
  static getQueryParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };
  static isEthiopian(name){
    if (typeof name ==='undefined'){
      return false;
    }
    return name.toLowerCase().includes('ethiopia');
  }
  static isTanzanian(name){
    if (typeof name ==='undefined'){
      return false;
    }
    return name.toLowerCase().includes('tanzania');
  }
  static isKenyan(name){
    if (typeof name ==='undefined'){
      return false;
    }
    return name.toLowerCase().includes('kenya');
  }
  static getColumnsToDisplay(originalColumns, columnsToDisplay){
    return originalColumns.filter(col => columnsToDisplay.includes(col.id));
  }
  static calculateSalaryAdvanceInterest(
    amount,
    workpay_interest_rate
) {
  var amount = parseFloat(amount);
  var workpay_interest_rate = parseFloat(workpay_interest_rate);
  var interest_charged = (amount * workpay_interest_rate) / 100;
  return { interest_charged: interest_charged };
}
  static isOfSector(companies,sector){
    const company = this.recordFromIndexedDataWithDefault(companies,this.authSwitchedCompanyID(),{sector:''});
    if(this.isNull(company)){
      return false;
    }
    return company.sector.toLowerCase().includes(sector.toLowerCase());
  }
}

String.prototype.toProperCase = function () {
  if(this.length<2 || this.toLowerCase() === 'mywallet'){
    return this;
  }
  return this.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
String.prototype.toTitleCase = function () {
  try{
    let sentence = this.toLowerCase().split(" ");
    for(let i = 0; i< sentence.length; i++){
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    sentence = sentence.join(" ");
    return sentence;
  }catch (e) {
    return this;
  }
};

Array.prototype.where = function(key, operator, value) {
  const records = [];
  switch (operator) {
    case "=":
      this.forEach(item => {
        if (item[key] == value) {
          records.push(item);
        }
      });
      break;
    case "==":
      this.forEach(item => {
        if (item[key] === value) {
          records.push(item);
        }
      });
      break;
    case "!=":
      this.forEach(item => {
        if (item[key] != value) {
          records.push(item);
        }
      });
      break;
    case "!==":
      this.forEach(item => {
        if (item[key] !== value) {
          records.push(item);
        }
      });
      break;
    case ">":
      this.forEach(item => {
        if (item[key] > value) {
          records.push(item);
        }
      });
      break;
    case "<":
      this.forEach(item => {
        if (item[key] < value) {
          records.push(item);
        }
      });
      break;
    case ">=":
      this.forEach(item => {
        if (item[key] >= value) {
          records.push(item);
        }
      });
      break;
    case "<=":
      this.forEach(item => {
        if (item[key] <= value) {
          records.push(item);
        }
      });
      break;
  }
  return records;
};
Array.prototype.where = function(callbackFunction) {
  const records = [];
  if (typeof callbackFunction !== "function") {
    return this;
  }
  this.forEach(item => {
    if (callbackFunction(item)) {
      records.push(item);
    }
  });
  return records;
};
Array.prototype.whereFirst = function(callbackFunction) {
  if (typeof callbackFunction !== "function") {
    return this;
  }
  let length = this.length;
  for (let i = 0; i < length; i++) {
     let item = this[i];
    if (callbackFunction(item)) {
      return item;
    }
  }
  return null;
};
Array.prototype.containsValue = function(value) {
  for (let i = 0; i < this.length; i++) {
    if (this[i] === value || (this[i] == value && !Helpers.isNull(value))) {
      return true;
    }
  }
  return false;
};
Array.prototype.first = function() {
  if (this.length === 0) {
    return null;
  }
  return this[0];
};
Array.prototype.last = function() {
  if (this.length === 0) {
    return null;
  }
  return this[this.length - 1];
};
Array.prototype.count = function() {
  return this.length;
};
Array.prototype.firstWithDefault = function(array) {
  if (this.length === 0) {
    return array;
  }
  return this[0];
};
Array.prototype.lastWithDefault = function(array) {
  if (this.length === 0) {
    return array;
  }
  return this[this.length - 1];
};
Array.prototype.unique = function(key) {
  if (this.length === 0) {
    return [];
  }
  let final_array = [];
  let unique_values = [];
  let length = this.length;
  for (let i = 0; i < length; i++) {
    let record = this[i];
    let value = record[key];
    if (!Helpers.isNull(value)) {
      if (unique_values.indexOf(value) === -1) {
        unique_values.push(value);
        final_array.push(record);
      }
    }
  }
  return final_array;
};
String.prototype.replaceAll = function(occurrence, replacement) {
  return this.replace(new RegExp(occurrence, "g"), replacement);
};
Date.prototype.addHours = function(h) {
  this.setHours(this.getHours() + h);
  return this;
};
Array.prototype.pluck = function(key) {
  if (this.length === 0) {
    return [];
  }
  let final_array = [];
  let length = this.length;
  for (let i = 0; i < length; i++) {
    let record = this[i]?this[i]:{};
    let value = record[key];
    if (!Helpers.isNull(value)) {
      final_array.push(value);
    }
  }
  return final_array;
};
Array.prototype.sumByKeys = function(keys) {
  if (this.length === 0 || !keys) {
    return {};
  }
  let info = {};
  keys.forEach(key=>{
      info[key] = 0;
  });
  let length = this.length;
  for (let i = 0; i < length; i++) {
    let record = this[i];
    keys.forEach(key=>{
      let value = record[key];
      if (!Helpers.isNull(value)) {
        info[key]+=Number(value);
      }
    });
  }
  return info;
};



